import React from "react";
import styled from "styled-components";
import Logo from "../../assets/logo_bb_white.svg";

const Wrapper = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: start;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  ${props =>
    !props.isTransparent &&
    `
    background-color: #211931;
    border-style: solid;
    width: 100%;
    display: flex;
    border-left: transparent;
    border-right: transparent;
    border-top: transparent;
    border-width: thin;
    border-image: linear-gradient(var(--angle), #6462ed, #c567ff, #fea152) 1;
    --angle: 0deg;
    animation: 10s rotate ease-in-out infinite;

    @keyframes rotate {
      to {
        --angle: 90deg;
      }
    }

    @property --angle {
      syntax: "<angle>";
      initial-value: 0deg;
      inherits: false;
    }
   `}
`;

export default function Header({ isTransparent }) {
  return (
    <Wrapper isTransparent={isTransparent}>
      <img width={200} src={Logo} alt="Blockbets" />
    </Wrapper>
  );
}
