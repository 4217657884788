import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider } from "@ethersproject/providers";
import { INFURA_ID } from "../constants/env";

const WalletAugmentedContext = React.createContext();

function useWalletAugmented() {
  return useContext(WalletAugmentedContext);
}

function WalletAugmented({ children }) {
  const [injectedProvider, setInjectedProvider] = useState("");

  const web3Modal = new Web3Modal({
    // network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: INFURA_ID,
        },
      },
    },
  });

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(provider));
  }, [setInjectedProvider]);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const contextValue = useMemo(() => ({ injectedProvider, loadWeb3Modal, logoutOfWeb3Modal, web3Modal }), [
    injectedProvider,
    web3Modal,
  ]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  return <WalletAugmentedContext.Provider value={contextValue}>{children}</WalletAugmentedContext.Provider>;
}

function WalletProvider({ children }) {
  return <WalletAugmented>{children}</WalletAugmented>;
}

export { useWalletAugmented as useWallet, WalletProvider };
