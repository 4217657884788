export const PLATFORM = {
  psn: "psn",
  xlb: "xbl",
  atvi: "atvi",
  bnet: "battlenet",
};

export const Games = {
  wz: "warzone",
};
