import React from "react";
import styled from "styled-components";

const Wrap = styled.span`
  background-color: #f3ec78;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: ${props => props.fontSize};

  ${props => props.type === "standard" && `background-image: linear-gradient(45deg, #6462ed, #c567ff, #fea152);`}
  ${props =>
    props.type === "alert" && `background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);`}
  ${props =>
    props.type === "success" && `background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);`}
  ${props =>
    props.type === "pending" && `background-image: linear-gradient(19deg, #3EECAC 17%, #EE74E1 43%, #b721ff 83%);`}
`;

function GradientText({ type, text, fontSize }) {
  return (
    <Wrap type={type} fontSize={fontSize}>
      {text}
    </Wrap>
  );
}

export default GradientText;
