/* Hook to be used in api calls */
import axios from "axios";
import { ENDPOINT } from "../constants/endpoints";

axios.defaults.baseURL = ENDPOINT.BASE_URL;

export async function callAxios({ url, method, body = null, headers = null }) {
  const result = await axios[method](url, JSON.parse(headers), JSON.parse(body))
    .then(res => {
      return { data: res.data };
    })
    .catch(err => {
      return { err };
    });

  return result;
}

export default callAxios;
