import React, { useState } from "react";
import styled from "styled-components";
import { Games, GameStatus } from "../../../constants/games";
import GradientText from "./GradientText";
import mw1 from "../../../assets/mw1.webp";
import mw2 from "../../../assets/mw2.webp";
import mw3 from "../../../assets/mw3.webp";
import mw4 from "../../../assets/mw4.webp";
import wz1 from "../../../assets/wz1.webp";
import wz2 from "../../../assets/wz2.webp";
import wz3 from "../../../assets/wz3.webp";
import wz4 from "../../../assets/wz4.webp";

const Wrap = styled.div`
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 250px;
  border: solid;
  border-width: thin;
  border-radius: 5px;
  border-color: ${props => props.borderColor};
  display: flex;
  cursor: pointer;
`;

const Button = styled.button`
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border-color: ${props => props.borderColor};
  border-width: thin;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  background-color: rgb(0, 0, 0, 0.8);
  transition: transform .4s;
  visibility: hidden;
`;

const Overlay = styled.div`
  background-color: rgb(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;

  &:hover {
    background-color: rgb(0, 0, 0, 0.3);
  }

  &:hover ${Button} {
    visibility: visible;
    transform: scale(2);
  }
`;

const DataBox = styled.div`
  background-color: rgb(0, 0, 0, 0.3);
  width: 100%;
  height: 35%;
  padding: 5px;
`;

function ChallengeCard({ item, handleProcess, gamerTag }) {
  const [buttonVisible, setButtonVisible] = useState(true);
  const [status, setStatus] = useState(item.status);
  const mwImages = [mw3, mw1, mw3, mw4];
  const wzImages = [wz1, wz2, wz3, wz4];

  let image = mw1;
  // const index = Math.floor(Math.random() * 4);
  // This is will be changed once list items are memorized.
  const index = 0;
  if (item.rawMode == Games.COD.MW) {
    image = mwImages[index];
  } else {
    image = wzImages[index];
  }
  const hanldeActionClick = () => {
    setButtonVisible(false);
    if (item.rawMode !== "3") {
      setStatus("Processing... it may take some time.");
    }
    handleProcess(item);
  };
  const indexStatus = parseInt(item.statusRaw, 10);
  const statusData = GameStatus[indexStatus];
  let buttonText = "";
  let resultText = "";

  if (item.won) {
    resultText = GameStatus[2].statusText + item.amount;
  } else if (!item.won && (item.statusRaw === "2" || item.statusRaw === "3")) {
    resultText = GameStatus[3].statusText;
    buttonText = GameStatus[3].buttonText;
  } else {
    resultText = statusData.statusText;
    if (!item.outgoing && item.statusRaw !== "4") {
      buttonText = "Accept";
    } else {
      buttonText = statusData.buttonText;
    }
  }

  const btn = React.useMemo(() => {
    return (
      <div>
        {!item.won && buttonVisible && indexStatus !== 1 && indexStatus !== 5 && (
          <Button borderColor="#b721ff" onClick={hanldeActionClick}>
            <GradientText text={buttonText} fontSize={12} type={statusData.statusType} />
          </Button>
        )}
      </div>
    );
  }, [buttonVisible]);

  const satusComp = React.useMemo(() => {
    return (
      <span style={{ fontSize: 12 }}>
        Status: <GradientText text={status} fontSize={12} type={statusData.statusType} />
      </span>
    );
  }, [status]);

  return (
    <Wrap img={image} borderColor={statusData.color}>
      <Overlay>
        <div style={{ height: "65%", display: "flex", justifyContent: "center", alignItems: "center" }}>{btn}</div>
        <DataBox>
          <div style={{ display: "flex", flexDirection: "column", paddingLeft: 7, textAlign: "left" }}>
            <span style={{ fontSize: 18 }}>Call of Duty: {item.gameMode}</span>
            <span style={{ fontSize: 16 }}>
              Opponent:{" "}
              <GradientText
                type="standard"
                text={gamerTag === item.usertag1 ? item.usertag2 : item.usertag1}
                fontSize={14}
              />
            </span>
            <div style={{ width: "98%", display: "flex", justifyContent: "space-between" }}>
              {satusComp}
              <GradientText text={resultText} type="success" fontSize="15px" />
            </div>
          </div>
        </DataBox>
      </Overlay>
    </Wrap>
  );
}

export default ChallengeCard;
