export const Games = {
  COD: {
    MW: 1,
    WZ: 2,
    WZ_KILL_RACE: 3,
  },
};

export const GameStatus = [
  {
    id: "0",
    desc: "Pending",
    currentStatus: "Pending to be accepted",
    statusText: "",
    statusType: "pending",
    buttonText: "Cancel",
    color: "#b721ff",
    won: null,
  },
  {
    id: "1",
    desc: "Accepted",
    currentStatus: "Accepted - Go Play!",
    statusText: "",
    statusType: "alert",
    buttonText: null,
    color: "#ff2280",
    won: null,
  },
  {
    id: "2",
    desc: "Finished - Won",
    currentStatus: "Finished",
    statusText: "You Won $",
    statusType: "success",
    buttonText: null,
    color: "#333333",
    won: true,
  },
  {
    id: "3",
    desc: "Finished - Lose",
    currentStatus: "Finished",
    statusText: "ups! You lost.",
    statusType: "success",
    buttonText: "Double or nothing!",
    color: "#333333",
    won: false,
  },
  {
    id: "4",
    desc: "Finished - Tied",
    currentStatus: "Finished",
    statusText: "Tied",
    statusType: "success",
    buttonText: "Double or nothing!",
    color: "#333333",
    won: false,
  },
  {
    id: "5",
    desc: "Cancelled",
    currentStatus: "Cancelled",
    statusText: "Cancelled",
    statusType: "success",
    buttonText: null,
    color: "#333333",
    won: null,
  },
];
