import { useEffect, useState } from "react";
import firebase from "firebase/app";
import { GDocuments } from "../constants/documents";
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MSG_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from "../constants/env";
// Required for side-effects
require("firebase/firestore");

firebase.initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MSG_SENDER_ID,
  appId: FIREBASE_APP_ID,
});

const db = firebase.firestore();
/*
  Get address information from the cloud
*/
export function useGAddress(address) {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    let cancelled = false;
    async function fetchProfile() {
      setFetching(true);
      try {
        const doc = db.collection(GDocuments.users);
        const docData = await doc.where("address", "==", address).limit(1).get();
        docData.forEach(item => {
          setData(item.data());
        });
        setFetching(false);
      } catch (e) {
        console.log("ERROR: ", e);
        setFetching(false);
      }
    }

    fetchProfile();
    return () => {
      cancelled = true;
    };
  }, [address]);

  return { data, fetching };
}

/*
  Get nickname information from the cloud
*/
export function useNickName(nick) {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    let cancelled = false;
    async function fetchProfile() {
      setFetching(true);
      try {
        const doc = db.collection(GDocuments.users);
        const docData = await doc.where("nick", "==", nick.toUpperCase()).limit(1).get();
        if (docData.size > 0) {
          docData.forEach(item => {
            setData(item.data());
          });
        } else {
          setData(null);
        }
        setFetching(false);
      } catch (e) {
        setFetching(false);
      }
    }

    fetchProfile();
    return () => {
      cancelled = true;
    };
  }, [nick]);

  return { data, fetching };
}

/*
  Associate Activision account to the address recieved by parm
*/
export async function setConnectActivision(address, gamerTag, platform, nickName) {
  if (address && gamerTag && platform && nickName) {
    const data = {
      address,
      nick: nickName.toUpperCase(),
      aTag: gamerTag,
      platform,
    };
    try {
      const doc = await db.collection(GDocuments.users).add(data);
      return doc;
    } catch (e) {
      console.log(e);
    }
  }
  return undefined;
}
