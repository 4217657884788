import React, { useState } from "react";
import { Button, Input, InputNumber, message, Modal, DatePicker, Select, Tag } from "antd";
import moment from "moment";
import styled from "styled-components";
import { Provider, useDispatch } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import { connectStateResults, connectSearchBox, InstantSearch } from "react-instantsearch-dom";
import { getAddress } from "@ethersproject/address";
import { useUserAddress } from "eth-hooks";
import { useActivity } from "../../providers/ActivityProvider";
import { useAppState } from "../../providers/AppStateProvider";
import { useWallet } from "../../providers/WalletProvider";
import { useContractLoader, useGasPrice } from "../../hooks";
import { Transactor } from "../../helpers";
import actions from "../../actions/challenge-actions";
import store from "../../store";
import { setProcessing } from "./globalSlice";
import { UserChallenges } from "../../components";
import Loader from "../../components/v1/Loader";
import ConnectAccount from "../../components/v1/ConnectAccount";
import GameType from "../../components/v1/GameType";
import CodCard from "../../components/v1/CodCard";
import polygon from "../../assets/matic.svg";
import { BorderedDiv, ChildRowDiv } from "../../components/v1/common/BorderedDiv";
import { Games } from "../../constants/games";
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID, CHAIN_ID } from "../../constants/env";
import { NETWORK } from "../../constants";

// Assets
import warzone from "../../assets/wzhome.jpeg";
import mw from "../../assets/mwhome.jpeg";
import wzkill from "../../assets/wzkillhome2.jpeg";

const DEFAULT_RACE_TIME = 15;
const DEFAULT_AOUNT = "1";
const { utils } = require("ethers");

const searchClient = algoliasearch(ALGOLIA_API_KEY, ALGOLIA_APP_ID);

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 95%;
  max-width: 1940px;
  margin-top: 10px;

  @media (max-width: 1200px) {
    width: 97%;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const DatePickerView = ({ handleSetDate, handleSetTime }) => {
  return React.useMemo(() => {
    const { Option } = Select;
    return (
      <div>
        <DatePicker
          format="YYYY-MM-DD HH:mm"
          showTime={{ defaultValue: moment() }}
          placeholder="Start Date"
          onChange={handleSetDate}
        />
        <Select defaultValue="15 min" style={{ marginLeft: 5, width: 100 }} allowClear onChange={handleSetTime}>
          <Option value="15">15 min</Option>
          <Option value="30">30 min</Option>
          <Option value="45">45 min</Option>
          <Option value="60">1 hour</Option>
        </Select>
      </div>
    );
  }, []);
};

export default function Main() {
  const { addActivity } = useActivity();
  const { isLoading, aTag } = useAppState();
  const [challengeVisible, setChallengeVisible] = useState(false);
  const [opponentAddress, setOpAddress] = useState("");
  const [opponenttag, setOpTag] = useState("");
  const [amount, setAmount] = useState(utils.parseEther(DEFAULT_AOUNT));
  const [sending, setSending] = useState(false);
  const [selectedOpponent, setSelectedOpponent] = useState(null);
  const [game, setGame] = useState(Games.COD.MW);
  const [raceDate, setRaceDate] = useState(null);
  const [racetime, setRaceTime] = useState(DEFAULT_RACE_TIME);

  const { injectedProvider, logoutOfWeb3Modal } = useWallet();
  const address = useUserAddress(injectedProvider);
  const challengeContract = useContractLoader(injectedProvider);
  const targetNetwork = NETWORK(CHAIN_ID);
  const gasPrice = useGasPrice(targetNetwork, "fast");
  const tx = Transactor(injectedProvider, gasPrice);
  const dispatch = useDispatch();

  if (isLoading) {
    return <Loader />;
  }

  const handleClean = () => {
    setOpAddress(null);
    setOpTag(null);
    setSelectedOpponent(null);
    setAmount(utils.parseEther("1"));
  };

  const handleAmountChange = value => {
    if (value > 0) {
      const bigAmount = utils.parseEther(`${value}`);
      setAmount(bigAmount);
    }
  };

  const handleDN = row => {
    const handleAmount = utils.parseEther(row.amount).mul(2);
    const gamer2 = row.outgoing ? row.usertag2 : row.usertag1;
    const opAddress = row.outgoing ? row.player2 : row.player1;
    setOpAddress(opAddress);
    setOpTag(gamer2);
    setAmount(handleAmount);
    setGame(row.rawMode);
    setChallengeVisible(true);
  };

  const handlePlaceChallenge = async () => {
    if (opponentAddress && opponenttag && amount.gt(0)) {
      const addr = getAddress(opponentAddress);
      setSending(true);
      const splitted = aTag.split("#");
      const gamerTag = splitted !== undefined && splitted.length > 0 ? splitted[0] : aTag;
      const opSplitted = opponenttag.split("#");
      const opponentTagSplitted = opSplitted !== undefined && opSplitted.length > 0 ? opSplitted[0] : opponenttag;
      let killRaceTime = 0;
      let killRaceEndTime = 0;
      if (game === Games.COD.WZ_KILL_RACE) {
        killRaceTime = raceDate * 1000;
        killRaceEndTime = moment.unix(raceDate).add(racetime, "minutes").unix() * 1000;
      }

      dispatch(setProcessing(true));

      addActivity(
        tx(
          challengeContract.CodChallenges.placeChallenge(
            gamerTag,
            opponentTagSplitted,
            addr,
            amount,
            game,
            killRaceTime,
            killRaceEndTime,
            { value: `${amount}` },
          ),
        ),
        actions.CHALLENGE_PLACE,
        "Processing challenge",
      );

      dispatch(setProcessing(false));
      setSending(false);
      handleClean();
      setChallengeVisible(false);
    }
  };

  const handleSetCompDate = date => {
    if (date != null) {
      setRaceDate(date.unix());
    }
  };

  const handleSetCompTime = time => {
    if (time != null) {
      setRaceTime(time);
    }
  };

  const handleChallengeMode = mode => {
    setGame(mode);
    setChallengeVisible(true);
  };

  const handleSelectOpponent = item => {
    setSelectedOpponent(item);
    setOpTag(item.aTag);
    setOpAddress(item.address);
  };

  const StateResults = ({ searchResults }) => {
    if (searchResults && searchResults.query) {
      return (
        <BorderedDiv>
          {searchResults.hits.map(item => (
            <div
              key={item.address}
              onClick={event => {
                event.preventDefault();
                handleSelectOpponent(item);
              }}
            >
              <ChildRowDiv>{item.nick}</ChildRowDiv>
            </div>
          ))}
        </BorderedDiv>
      );
    }
    return <div />;
  };

  const SearchBox = ({ currentRefinement, refine }) => (
    <Input placeholder="Search Opponent" onChange={event => refine(event.target.value)} />
  );

  const CustomStateResults = connectStateResults(StateResults);
  const CustomSearchBox = connectSearchBox(SearchBox);

  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", textAlign: "-webkit-center" }}>
      {aTag ? (
        <>
          <Provider store={store}>
            <GameType />
            <CardsWrapper>
              <CodCard
                title="Warzone Kill Race"
                subTitle="Set the time, we will take your best result!"
                image={wzkill}
                challenge={() => {
                  handleChallengeMode(Games.COD.WZ_KILL_RACE);
                }}
                bottom
              />
              <CodCard
                title="Warzone 1v1"
                subTitle="Let's go for a challenge!"
                image={warzone}
                challenge={() => {
                  handleChallengeMode(Games.COD.WZ);
                }}
                bottom={false}
              />
              <CodCard
                title="Modern Warfare"
                subTitle="Challenge a friend!"
                image={mw}
                challenge={() => {
                  handleChallengeMode(Games.COD.MW);
                }}
                bottom
              />
            </CardsWrapper>
            <div style={{ marginTop: 40, width: "100%", justifyContent: "center" }}>
              <UserChallenges tx={tx} writeContracts={challengeContract} address={address} handleDN={handleDN} />
            </div>
            <Modal
              visible={challengeVisible}
              onCancel={() => {
                handleClean();
                setChallengeVisible(false);
              }}
              style={{
                border: "1px solid #cccccc",
                borderRadius: 10,
                padding: 16,
                width: 800,
              }}
              footer={[
                <Button type="primary" onClick={handlePlaceChallenge} loading={sending}>
                  SEND
                </Button>,
              ]}
            >
              <div>
                <div style={{ height: 40, width: "100%", marginBottom: 10 }}>
                  <span style={{ fontFamily: "Gotham", fontWeight: "600", fontSize: 20 }}>
                    {game === Games.COD.MW
                      ? "Modern Warfare: 1 vs 1"
                      : game === Games.COD.WZ_KILL_RACE
                      ? "Warzone: Kill Race"
                      : "Warzone: 1 vs 1"}
                  </span>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <span>Your Gaming Tag: </span>
                  <Tag color="magenta" visible>
                    {aTag.substring(0, aTag.lastIndexOf("#"))}
                  </Tag>
                </div>
                <div style={{ marginBottom: 16 }}>
                  {selectedOpponent ? (
                    <div>
                      <span>Opponent Tag: </span>
                      <Tag
                        color="green"
                        closable
                        onClose={e => {
                          e.preventDefault();
                          handleClean();
                        }}
                      >
                        {selectedOpponent.aTag.substring(0, selectedOpponent.aTag.lastIndexOf("#"))}
                      </Tag>
                    </div>
                  ) : (
                    <InstantSearch searchClient={searchClient} indexName="firestore_nickname">
                      <CustomSearchBox />
                      <CustomStateResults />
                    </InstantSearch>
                  )}
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Input placeholder="Opponent Address" value={opponentAddress} readOnly />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Input placeholder="Opponent Gaming Tag" value={opponenttag} readOnly />
                </div>
                {game === Games.COD.WZ_KILL_RACE && (
                  <div style={{ marginBottom: 16 }}>
                    <DatePickerView handleSetDate={handleSetCompDate} handleSetTime={handleSetCompTime} />
                  </div>
                )}
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <InputNumber
                    placeholder="Amount in $Matic"
                    defaultValue={1}
                    min={0}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={value => handleAmountChange(value)}
                    style={{ width: 80 }}
                  />
                  <img alt="polygon" src={polygon} width={35} style={{ marginLeft: 5 }} />
                </div>
                <a href="https://app.honeyswap.org" target="_blank" rel="noreferrer">
                  <span style={{ fontFamily: "Gotham", color: "rgb(243, 66, 120)" }}>Do you need matic tokens?</span>
                </a>
              </div>
            </Modal>
          </Provider>
        </>
      ) : (
        <ConnectAccount address={address} logout={logoutOfWeb3Modal} />
      )}
    </div>
  );
}
