import React from "react";
import styled from "styled-components";
import { Button } from "antd";

const Wrapper = styled.div`
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  border-color: #c567ff;
  # border-image: linear-gradient(var(--angle), #6462ed, #c567ff, #fea152) 1;
  --angle: 0deg;
  animation: 10s rotate ease-in-out infinite;
  background-size: cover;
  height: 380px;
  width: 560px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 30px;
  background-image: url(${props => props.theme.backgroundimage});

  @media (max-width: 1440px) {
    width: 378px;
    height: 350px;
  }

  @media (max-width: 1270px) {
    width: 368px;
    height: 320px;
  }

  @media (max-width: 1200px) {
    width: 510px;
    height: 280px;
  }

  @media (max-width: 1040px) {
    width: 398px;
    height: 370px;
  }

  @media (max-width: 768px) {
    width: 100vh;
    height: 250px;
  }
`;

export default function Header({ title, subTitle, image, challenge, bottom }) {
  return (
    <Wrapper theme={{ backgroundimage: image }}>
      {bottom && <div style={{ height: "70%" }} />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 15,
          backgroundColor: "#44424780",
          alignItems: "center",
          height: "30%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "27px",
              color: "#FFFFFF",
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "29px",
              color: "#FFFFFF",
              marginTop: "10px",
            }}
          >
            {subTitle}
          </span>
        </div>
        <Button type="primary" style={{ width: 160, height: 40 }} onClick={challenge}>
          CHALLENGE
        </Button>
      </div>
    </Wrapper>
  );
}
