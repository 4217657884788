// FIREBASE
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MSG_SENDER_ID = process.env.REACT_APP_FIREBASE_MSG_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

// ALGOLIA
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;

// CHAIN
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ? process.env.REACT_APP_CHAIN_ID : 137;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN;

// INFURA
export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
