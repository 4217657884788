import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useUserAddress } from "eth-hooks";
import { message } from "antd";
import StoredList from "../StoredList";
import { getNetworkType } from "../lib/web3-utils";
import { useWallet } from "./WalletProvider";
import {
  ACTIVITY_STATUS_CONFIRMED,
  ACTIVITY_STATUS_FAILED,
  ACTIVITY_STATUS_PENDING,
  ACTIVITY_STATUS_TIMED_OUT,
} from "../constants/activity-statuses";
import actions from "../actions/challenge-actions";

const ActivityContext = React.createContext();

// Only used to serialize / deserialize the symbols
const StatusSymbolsByName = new Map(
  Object.entries({
    ACTIVITY_STATUS_CONFIRMED,
    ACTIVITY_STATUS_FAILED,
    ACTIVITY_STATUS_PENDING,
    ACTIVITY_STATUS_TIMED_OUT,
  }),
);

const TypeSymbolsByName = new Map(Object.entries(actions));

const MINUTE = 60 * 1000;
const TIMEOUT_DURATION = 10 * MINUTE;

function getStoredList(account) {
  return new StoredList(`activity:${getNetworkType()}:${account}`, {
    preStringify: activity => ({
      ...activity,
      status: activity.status.description.replace("ACTIVITY_STATUS_", ""),
      type: activity.type ? activity.type.description : "",
    }),
    postParse: activity => ({
      ...activity,
      status: StatusSymbolsByName.get(`ACTIVITY_STATUS_${activity.status}`),
      type: TypeSymbolsByName.get(activity.type),
    }),
  });
}

async function getActivityFinalStatus(ethers, { createdAt, transactionHash, status }) {
  if (status !== ACTIVITY_STATUS_PENDING) {
    return status;
  }

  const now = Date.now();

  return Promise.race([
    // Get the transaction status once mined
    ethers
      .getTransaction(String(transactionHash))
      .then(tx => {
        // tx is null if no tx was found
        if (!tx) {
          throw new Error("No transaction found");
        }
        return tx.wait().then(receipt => {
          return receipt.blockNumber ? ACTIVITY_STATUS_CONFIRMED : ACTIVITY_STATUS_FAILED;
        });
      })
      .catch(() => {
        return ACTIVITY_STATUS_FAILED;
      }),

    // Timeout after 10 minutes
    new Promise(resolve => {
      if (now - createdAt > TIMEOUT_DURATION) {
        return ACTIVITY_STATUS_TIMED_OUT;
      }
      setTimeout(() => {
        resolve(ACTIVITY_STATUS_TIMED_OUT);
      }, TIMEOUT_DURATION - (now - createdAt));
    }),
  ]);
}

function ActivityProvider({ children }) {
  const [activities, setActivities] = useState([]);
  const storedList = useRef(null);
  const { injectedProvider } = useWallet();
  const address = useUserAddress(injectedProvider);

  // Update the activities, ensuring the activities
  // are updated in the stored list and in the state.
  const updateActivities = useCallback(
    cb => {
      const newActivities = cb(activities);
      setActivities(newActivities);
      if (storedList.current) {
        storedList.current.update(newActivities);
      }
    },
    [activities],
  );

  // Add a single activity.
  const addActivity = useCallback(
    async (tx, type, description = "") => {
      try {
        // tx might be a promise resolving into a tx
        const result = await tx;
        console.log("AP", result);
        message.destroy();
        message.loading("Transaction in progress", 15).then(() => message.loading("Still processing", 0));

        updateActivities(activities => [
          ...activities,
          {
            createdAt: Date.now(),
            description,
            from: result.from,
            nonce: result.nonce,
            read: false,
            status: ACTIVITY_STATUS_PENDING,
            type,
            to: result.to,
            transactionHash: result.hash,
          },
        ]);

        return result;
      } catch (e) {
        message.destroy();
        console.log(e);
        console.log("Transaction Error:", e.message);
      }
    },
    [updateActivities],
  );

  // Clear a single activity
  const removeActivity = useCallback(
    transactionHash => {
      updateActivities(activities => activities.filter(activity => activity.transactionHash !== transactionHash));
    },
    [updateActivities],
  );

  // Clear all non pending activities − we don’t clear
  // pending because we’re awaiting state change.
  const clearActivities = useCallback(() => {
    updateActivities(activities => activities.filter(activity => activity.status === ACTIVITY_STATUS_PENDING));
  }, [updateActivities]);

  // Update the status of a single activity,
  // using its transaction hash.
  const updateActivityStatus = useCallback(
    (hash, status) => {
      updateActivities(activities =>
        activities.map(activity => {
          if (activity.transactionHash !== hash) {
            if (status === ACTIVITY_STATUS_CONFIRMED) {
              removeActivity(activity);
            } else {
              return activity;
            }
          }
          return { ...activity, read: false, status };
        }),
      );
    },
    [updateActivities],
  );

  // Mark the current user’s activities as read
  const markActivitiesRead = useCallback(() => {
    updateActivities(activities => activities.map(activity => ({ ...activity, read: true })));
  }, [updateActivities]);

  // Total number of unread activities
  const unreadCount = useMemo(() => {
    return activities.reduce((count, { read }) => count + Number(!read), 0);
  }, [activities]);

  const processingCount = useMemo(() => {
    return activities.reduce((count, { status }) => count + Number(status === ACTIVITY_STATUS_PENDING ? 1 : 0), 0);
  }, [activities]);

  const updateActivitiesFromStorage = useCallback(() => {
    if (!storedList.current) {
      return;
    }

    const activitiesFromStorage = storedList.current.getItems();
    console.log("Current activities: ", activitiesFromStorage);
    // We will diff activities from storage and activites from state to prevent loops in the useEffect below
    const activitiesChanged =
      activities.length !== activitiesFromStorage.length ||
      activitiesFromStorage.filter(
        ({ transactionHash }) => activities.findIndex(activity => activity.transactionHash === transactionHash) === -1,
      ) > 0;
    if (activitiesChanged) {
      setActivities(activitiesFromStorage);
    }
  }, [activities]);

  // Triggered every time the account changes
  useEffect(() => {
    if (!address) {
      return;
    }

    let cancelled = false;
    storedList.current = getStoredList(address);
    updateActivitiesFromStorage();

    activities.forEach(async activity => {
      const status = await getActivityFinalStatus(injectedProvider, activity);
      if (!cancelled && status !== activity.status) {
        updateActivityStatus(activity.transactionHash, status);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [address, activities, injectedProvider, updateActivitiesFromStorage, updateActivityStatus]);

  return (
    <ActivityContext.Provider
      value={{
        activities,
        addActivity,
        clearActivities,
        markActivitiesRead,
        processingCount,
        removeActivity,
        unreadCount,
        updateActivities,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
}

ActivityProvider.propTypes = {
  children: PropTypes.node,
};

function useActivity() {
  return useContext(ActivityContext);
}

export { ActivityProvider, useActivity };
