/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import { Spin, List } from "antd";
import { useQuery, gql } from "@apollo/client";
import { sortBy } from "lodash";
import { useActivity } from "../providers/ActivityProvider";
import { useAppState } from "../providers/AppStateProvider";
import { GameStatus } from "../constants/games";
import GradientText from "./v1/common/GradientText";
import ChallengeCard from "./v1/common/ChallengeCard";
import actions from "../actions/challenge-actions";

const { utils } = require("ethers");

const UserChallenges = ({ tx, address, writeContracts, handleDN }) => {
  const profile = useAppState();
  const splitted = profile.aTag.split("#");
  const gamerTag = splitted !== undefined && splitted.length > 0 ? splitted[0] : profile.aTag;
  const userCha = gql`
  query getChallengesByAddr {
    challenges(where: {player1: "${address}"}) {
      id
      player1
      player2
      gamer1
      gamer2
      amount
      gameMode
      accepted
      settled
      status
      winner
    }
  }
  `;
  const incomingChallenges = gql`
  query incoming {
    challenges(where: {player2: "${address}"}) {
      id
      player1
      player2
      gamer1
      gamer2
      amount
      gameMode
      accepted
      settled
      status
      winner
    }
  }
  `;
  const {
    loading,
    error,
    data,
    startPolling: startChallengePolling,
    stopPolling: stopChallengePolling,
  } = useQuery(userCha, { pollingInterval: 8000 });
  const { data: dataIncoming, startPolling, stopPolling } = useQuery(incomingChallenges, { pollingInterval: 8000 });
  const { addActivity } = useActivity();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    // versionRefetch()
    if (!completed) {
      startPolling(8000);
      startChallengePolling(8000);
    } else {
      stopPolling();
      stopChallengePolling();
    }
    return () => {
      stopPolling();
      stopChallengePolling();
    };
  }, [stopPolling, startPolling, startChallengePolling, stopChallengePolling, completed]);

  if (loading) {
    return (
      <div>
        <Spin size="large" />
      </div>
    );
  }

  const handleAccept = async (index, amount) => {
    const parsedIndex = parseInt(index, 10);
    const parsedAmount = utils.parseEther(amount);
    addActivity(
      tx(writeContracts.CodChallenges.acceptChallenge(parsedIndex, { value: `${parsedAmount}` })),
      actions.CHALLENGE_ACCEPT,
      "Accepting challenge",
    );
  };

  const handleCancel = async index => {
    const parsedIndex = parseInt(index, 10);

    addActivity(
      tx(writeContracts.CodChallenges.removeChallenge(parsedIndex)),
      actions.CHALLENGE_CANCEL,
      "Canceling challenge",
    );
  };

  const handleDoubleOrNothing = async row => {
    if (row.rawMode === "3") {
      handleDN(row);
    } else {
      const amount = utils.parseEther(row.amount).mul(2);
      const gamer1 = row.outgoing ? row.usertag1 : row.usertag2;
      const gamer2 = row.outgoing ? row.usertag2 : row.usertag1;
      const opAddress = row.outgoing ? row.player2 : row.player1;

      addActivity(
        tx(
          writeContracts.CodChallenges.placeChallenge(gamer1, gamer2, opAddress, amount, row.rawMode, 0, 0, {
            value: `${amount}`,
          }),
        ),
        actions.CHALLENGE_PLACE,
        "Sending Double or Nothing",
      );
    }
  };

  const handleProcess = async row => {
    if (GameStatus[row.statusRaw].desc === "Pending" && row.outgoing === false) {
      await handleAccept(row.challengeid, row.amount);
    } else if (GameStatus[row.statusRaw].desc === "Pending" && row.outgoing === true) {
      await handleCancel(row.challengeid);
    } else if (GameStatus[row.statusRaw].currentStatus === "Finished") {
      handleDoubleOrNothing(row);
    }
  };

  let lastIndex = 0;
  const incoming = dataIncoming
    ? dataIncoming.challenges.map(el => {
        const winner = el.winner && el.winner === gamerTag;
        lastIndex += 1;
        return {
          key: lastIndex,
          challengeid: parseInt(el.id, 10),
          player1: el.player1,
          player2: el.player2,
          usertag1: el.gamer1,
          usertag2: el.gamer2,
          amount: utils.formatEther(el.amount),
          rawMode: el.gameMode,
          gameMode: (() => {
            if (el.gameMode === "1") return "Modern Warfare";
            if (el.gameMode === "2") return "Warzone";
            if (el.gameMode === "3") return "Warzone Kill Race";
            return "null";
          })(),
          status: (() => {
            const index = parseInt(el.status, 10);
            return GameStatus[index].currentStatus;
          })(),
          statusRaw: el.status,
          won: winner,
          outgoing: el.player1.toLowerCase() === address.toLowerCase(),
          accepted: el.accepted,
        };
      })
    : [];

  let dataSource = data
    ? data.challenges.map(el => {
        const winner = el.winner && el.winner === gamerTag;
        lastIndex += 1;
        return {
          key: lastIndex,
          challengeid: parseInt(el.id, 10),
          player1: el.player1,
          player2: el.player2,
          usertag1: el.gamer1,
          usertag2: el.gamer2,
          amount: utils.formatEther(el.amount),
          rawMode: el.gameMode,
          gameMode: (() => {
            if (el.gameMode === "1") return "Modern Warfare";
            if (el.gameMode === "2") return "Warzone";
            if (el.gameMode === "3") return "Warzone Kill Race";
            return "null";
          })(),
          status: (() => {
            const index = parseInt(el.status, 10);
            return GameStatus[index].currentStatus;
          })(),
          statusRaw: el.status,
          won: winner,
          outgoing: el.player1.toLowerCase() === address.toLowerCase(),
          accepted: el.accepted,
        };
      })
    : [];

  dataSource = dataSource.concat(incoming);
  dataSource = sortBy(dataSource, "challengeid").reverse();
  return (
    <div style={{ width: "80%" }}>
      {/* <Table dataSource={dataSource} columns={columns} width={800} rowClassName={(record) => {
        let aa = record.outgoing ? 'green' : 'red';
        return aa;
        } }/> */}
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <GradientText fontSize="28px" text="Your Challenges" type="standard" />
      </div>
      <List
        style={{ marginTop: 20 }}
        grid={{
          gutter: 30,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={dataSource}
        footer={<div></div>}
        renderItem={item => (
          <List.Item>
            <ChallengeCard item={item} handleProcess={handleProcess} gamerTag={gamerTag} />
          </List.Item>
        )}
      />
    </div>
  );
};
export default UserChallenges;
