import React from "react";
import { Image } from "antd";
import styled from "styled-components";
import Logo from "../../assets/logo_bb_white.svg";

const Span = styled.span`
  border-style: solid;
  border-width: 3px;
  border-radious: 10px;
  display: flex;
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
  border-image: linear-gradient(var(--angle), #6462ed, #c567ff, #fea152) 1;
  --angle: 90deg;
  cursor: pointer;

  @keyframes rotate {
    to {
      --angle: 90deg;
    }
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
`;

export default function GameType() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 120,
        justifyContent: "space-between",
        width: "11rem",
        fontSize: "1.4em",
        cursor: "pointer",
      }}
    >
      <Span>1 vs 1</Span>
      <span style={{ color: "dimgrey" }}>Tournament</span>
    </div>
  );
}
