import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useUserAddress } from "eth-hooks";
import { message } from "antd";
import { useGAddress } from "../hooks/GDocuments";
import { useWallet } from "./WalletProvider";
import { useActivity } from "./ActivityProvider";

const AppStateContext = React.createContext();

function AppStateProvider({ children }) {
  const { injectedProvider } = useWallet();
  const address = useUserAddress(injectedProvider);
  const { data, fetching } = useGAddress(address);
  const { processingCount } = useActivity();
  const appLoading = fetching;
  const txPending = processingCount > 0;

  if (processingCount === 0) {
    message.destroy();
  }

  return (
    <AppStateContext.Provider
      value={{
        isLoading: appLoading,
        txPending,
        address,
        aTag: data ? data.aTag : "",
        platform: data ? data.platform : "",
        nick: data ? data.nick : "",
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

AppStateProvider.propTypes = { children: PropTypes.node };

function useAppState() {
  return useContext(AppStateContext);
}

export { AppStateProvider, useAppState };
