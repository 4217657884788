import { callAxios } from "./Axios";
import { ENDPOINT } from "../constants/endpoints";
import { PLATFORM } from "../constants/tracker";

/*
  Get address information from the cloud
*/
export async function isActivisionIdAvailable(platform, user) {
  const hackPlatform = platform === PLATFORM.bnet ? PLATFORM.atvi : platform;
  const response = await callAxios({
    method: "get",
    url: `${ENDPOINT.VALIDATE_ID}?id=${encodeURIComponent(user)}&platform=${hackPlatform}`,
    headers: JSON.stringify({ accept: "*/*" }),
  });

  if (response && response.err) {
    return false;
  }
  return true;
}
