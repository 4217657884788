import React from "react";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "./App.css";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import store from "./store";
import { Firebase } from "./components";
import { AppStateProvider } from "./providers/AppStateProvider";
import { ActivityProvider } from "./providers/ActivityProvider";
import { WalletProvider } from "./providers/WalletProvider";
import Routes from "./views/Routes";
import WindowFocusHandler from "./views/general/WindowFocusHandler";

require("dotenv").config();
require("firebase/firestore");

const client = new ApolloClient({
  uri: "https://api.studio.thegraph.com/query/3452/cod2matic/v0.0.6",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <div className="App">
      <>
        <WalletProvider>
          <Provider store={store}>
            <ActivityProvider>
              <Firebase />
              <WindowFocusHandler />
              <AppStateProvider>
                <ApolloProvider client={client}>
                  <Routes />
                </ApolloProvider>
              </AppStateProvider>
            </ActivityProvider>
          </Provider>
        </WalletProvider>
      </>
    </div>
  );
}

// eslint-disable-next-line no-unused-expressions
window.ethereum &&
  window.ethereum.on("chainChanged", () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  });

export default App;
