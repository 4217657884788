import React, { useEffect, useCallback } from "react";
import { useWallet } from "../../providers/WalletProvider";
import { addNetwork, POLYGON_NETWORK } from "../../constants/chains";

const WindowFocusHandler = () => {
  const { injectedProvider } = useWallet();
  const addNewNetwork = useCallback(async () => {
    if (injectedProvider) {
      addNetwork(injectedProvider, POLYGON_NETWORK);
    }
  }, [injectedProvider]);

  // User has switched back to the tab
  const onFocus = () => {
    addNewNetwork();
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  });

  return <></>;
};

export default WindowFocusHandler;
