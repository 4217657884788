import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import bbAnimation from "../../assets/lotties/bb_animation.json";

const Wrapper = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
`;

function Loader() {
  const defaultOptions = {
    animationData: bbAnimation,
  };

  return (
    <Wrapper>
      <Lottie options={defaultOptions} height={100} width={100} />
    </Wrapper>
  );
}

export default Loader;
