import React from "react";
import image from "../../assets/2197694.jpg";
import Account from "./Account";
import Header from "./Header";

export default function ConnectScreen({
  userProvider,
  address,
  mainnetProvider,
  loadWeb3Modal,
  web3Modal,
  logoutOfWeb3Modal,
}) {
  return (
    <>
      <Header isTransparent />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            minHeight: "100vh",
            minWidth: "100%",
            alignItems: "center",
            background: `url(${image}) no-repeat center center fixed`,
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            filter: "blur(10px)",
            WebkitFilter: "blur(10px)",
          }}
        />
        <div
          style={{
            border: "1px solid #FFFFFF",
            borderRadius: 10,
            padding: 16,
            width: 400,
            zIndex: 1,
            background: "rgba(40,40,50, 0.7)",
          }}
        >
          <h2>Connect your wallet</h2>
          <Account
            address={address}
            localProvider={userProvider}
            userProvider={userProvider}
            mainnetProvider={mainnetProvider}
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            blockExplorer={null}
          />
        </div>
      </div>
    </>
  );
}
