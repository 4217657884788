import React, { useMemo, useState } from "react";
import { Button, Input, Modal, Radio, Tooltip } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { isActivisionIdAvailable } from "../../hooks/Tracker";
import { setConnectActivision, useNickName } from "../../hooks/GDocuments";
import { PLATFORM } from "../../constants/tracker";

export default function ConnectAccount({ address, logout }) {
  const [checking, setChecking] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [accountWithError, setAccountError] = useState(false);
  const [user, setUser] = useState(null);
  const [platform, setPlatform] = useState(PLATFORM.psn);
  const [nickName, setNick] = useState(null);
  const [nickError, setNickError] = useState(null);

  const nicknameInfo = useNickName(nickName);
  const NICK_LENGTH = "Nickname too short, we need 4 letters at least.";
  const NICK_ALREADY_EXISTS = "Nickname already taken.";

  const handlePlatformChange = value => {
    if (value !== undefined) {
      setPlatform(value.target.value);
    }
  };

  const handleUserChange = value => {
    setUser(value);
  };

  const handleNickChange = value => {
      setNick(value);
  };

  const handleFormSubmit = async () => {
    setAccountError(false);
    setChecking(true);
    const isValid = await isActivisionIdAvailable(platform, user);
    setChecking(false);
    if (isValid) {
      const result = await setConnectActivision(address, user, platform, nickName);
      window.location.reload(false);
    } else {
      setAccountError(!isValid);
    }
  };

  const nicknameInput = useMemo(() => {
    if (nickName > 4 && nicknameInfo.fetching) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input
            style={{ width: 400 }}
            placeholder="Choose your Blockbets Nickname"
            prefix={<UserOutlined style={{ marginRight: 10 }} />}
            onChange={e => handleNickChange(e.target.value)}
          />
          <Tooltip color="magenta" title={nickError}>
            <ExclamationCircleOutlined
              style={{ cursor: "pointer", fontSize: "18px", marginLeft: 10, alignSelf: "center" }}
            />
          </Tooltip>
        </div>
      );
    } else if (nickName?.length > 4 && nicknameInfo.data) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input
            style={{ width: 400 }}
            placeholder="Choose your Blockbets Nickname"
            prefix={<UserOutlined style={{ marginRight: 10 }} />}
            onChange={e => handleNickChange(e.target.value)}
          />
          <Tooltip color="magenta" title={NICK_ALREADY_EXISTS}>
            <ExclamationCircleOutlined
              style={{ cursor: "pointer", fontSize: "18px", marginLeft: 10, alignSelf: "center" }}
            />
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input
            style={{ width: 400 }}
            placeholder="Choose your Blockbets Nickname"
            prefix={<UserOutlined style={{ marginRight: 10 }} />}
            onChange={e => handleNickChange(e.target.value)}
          />
          {nickName?.length < 4 && (
            <Tooltip color="magenta" title={NICK_LENGTH}>
              <ExclamationCircleOutlined
                style={{ cursor: "pointer", fontSize: "18px", marginLeft: 10, alignSelf: "center" }}
              />
            </Tooltip>
          )}
        </div>
      );
    }
  }, [nickName, nicknameInfo]);

  return (
    <div>
      <Modal
        visible
        style={{
          border: "1px solid #cccccc",
          borderRadius: 10,
          padding: 16,
          width: 600,
        }}
        footer={[
          <Button
            key="back"
            htmlType="submit"
            disabled={!user || user === "" || nickName?.length < 4 || nicknameInfo.data || nicknameInfo.fetching}
            onClick={handleFormSubmit}
            loading={checking}
            >
            Create Account
          </Button>,
          <span>
            <a
              style={{ textDecoration: "underline", padding: 10 }}
              onClick={() => {
                logout();
              }}
            >
              Logout
            </a>
          </span>,
        ]}
      >
        <h2>Connect your gaming account</h2>
        <div style={{ marginTop: 15 }}>
          <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <span style={{ marginBottom: 5 }}>Platform:</span>
            <Radio.Group size="small" onChange={handlePlatformChange} value={platform}>
              <Radio value={PLATFORM.psn}>PlayStation</Radio>
              <Radio value={PLATFORM.xlb}>Xbox</Radio>
              {/* <Radio value={Platform.atvi}>Activision</Radio> */}
              <Radio value={PLATFORM.bnet}>Battle.net</Radio>
            </Radio.Group>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <span style={{ marginBottom: 10 }}>Nickname:</span>
            {nicknameInput}
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <span style={{ marginBottom: 10 }}>Activision Id (gamerTag#2233445):</span>
            <Input
              style={{ width: 400 }}
              placeholder="Activision Id (gamerTag#2233445)"
              prefix={<TeamOutlined style={{ marginRight: 10 }} />}
              onChange={e => handleUserChange(e.target.value)}
            />
            <div style={{textAlign: "right", marginRight: "40px", marginTop: "5px"}}>
            <a href="https://s.activision.com/activision/profile" target="_blank" rel="noreferrer">
                <span style={{ fontSize: "12px", color: "rgb(243, 66, 120)" }}>I don't know my Activision Id</span>
              </a>
            </div>
          </div>
          {checked && (
            <CheckCircleOutlined style={{ fontSize: "18px", color: "#37e710", marginLeft: 10, alignSelf: "center" }} />
          )}
          {error && (
            <Tooltip color="magenta" title="Profile should be public for friends and partners.">
              <ExclamationCircleOutlined
                style={{ cursor: "pointer", fontSize: "18px", marginLeft: 10, alignSelf: "center" }}
              />
            </Tooltip>
          )}
        </div>
        {accountWithError && (
          <div style={{ marginTop: 10 }}>
            {/** This link will be pointing to a medium article where we will explain how to change the configuration. */}
            <a href="https://hackmd.io/@aVUAvLLbRJatuPp30MmumQ/BJ7IuWEbY" style={{ textDecoration: "underline" }}>
              Account not found: How can I fix this?
            </a>
          </div>
        )}
      </Modal>
    </div>
  );
}
