import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border: solid;
  border-width: 1px;
  border-color: #7C00FA;
  border-radius: 4px;
`;

export function BorderedDiv({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

const ChildRow = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;

  &:hover {
    background-color: #7C00FA;
  }
`;

export function ChildRowDiv({ children }) {
  return <ChildRow>{children}</ChildRow>;
}
