export const POLYGON_NETWORK = {
  chainId: "0x89",
  chainName: "Matic(Polygon) Mainnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://rpc-mainnet.matic.network/"],
  blockExplorerUrls: ["https://polygonscan.com/"],
};

export async function addNetwork(injected, network) {
  await injected.provider.request({
    method: "wallet_addEthereumChain",
    params: [network],
  });
}
