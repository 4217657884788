import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { InfuraProvider } from "@ethersproject/providers";
import { useUserAddress } from "eth-hooks";
import { useWallet } from "../providers/WalletProvider";
import { Main } from ".";
import Header from "../components/v1/Header";
import ConnectScreen from "../components/v1/ConnectScreen";
import { Account } from "../components";

export default function Routes() {
  const { injectedProvider, web3Modal, loadWeb3Modal, logoutOfWeb3Modal } = useWallet();
  const address = useUserAddress(injectedProvider);
  const mainnetInfura = new InfuraProvider("homestead", "5b55e30382a746259ae44bb1f0eded23");
  return (
    <>
      {injectedProvider ? (
        <>
          <div>
            <Header />
            <BrowserRouter>
              <Switch>
                <Route>
                  <Main />
                </Route>
              </Switch>
            </BrowserRouter>
          </div>
          <div
            style={{
              position: "fixed",
              textAlign: "right",
              right: 0,
              top: 0,
              paddingRight: 20,
              paddingTop: 5,
              haight: 70,
              zIndex: 900,
            }}
          >
            <Account address="" mainnetProvider="" blockExplorer="" />
          </div>
        </>
      ) : (
        <ConnectScreen
          userProvider={injectedProvider}
          address={address}
          mainnetProvider={mainnetInfura}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
        />
      )}
    </>
  );
}
